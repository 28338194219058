<template>
  <div>
    <maca-modal titulo="Nuevo Viaje" :impedirClose="impedirClose" ref="modal">
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 0%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <div style="text-align: center">
            <el-form-item>
              <el-steps :space="200" :active="active" simple>
                <el-step icon="el-icon-truck" title="Transportistas"></el-step>
                <el-step icon="el-icon-truck" title="Vehículos"></el-step>
                <el-step icon="el-icon-user-solid" title="Pasajeros"></el-step>
                <el-step icon="el-icon-map-location" title="Rutas"></el-step>
                <el-step title="Finalizar"></el-step>
              </el-steps>
            </el-form-item>
          </div>
          <div v-show="active == 0" class="contenedorStep">
            <el-row :gutter="10">
              <el-col :span="10">
                <el-form-item
                  label="Buscar transportista por nombre"
                  prop="nombre"
                >
                  <maca-select-box
                    placeholder="Ingresar nombre de transportista"
                    :item-label="['apellido', 'nombre', 'razonSocial']"
                    v-model="form.transportista"
                    :url="urlTransportista"
                    :permitirInput="true"
                    :clearable="true"
                    @change="seleccionSelect"
                    :necesitaParams="true"
                    :getParams="getParams"
                  ></maca-select-box>
                </el-form-item>
              </el-col>

              <el-col :span="7">
                <!-- <el-form-item label="O filtrar por localidad/paraje">
                  <el-cascader
                    :props="props"
                    :show-all-levels="false"
                    ref="cascader"
                    placeholder="Seleccionar ubicación"
                    @change="handleChange"
                    :clearable="true"
                  ></el-cascader>
                </el-form-item>

                <span v-if="ubicacionIncompleta" style="color: red"
                  >Debe seleccionar la localidad o paraje para poder realizar la
                  búsqueda</span
                > -->
              </el-col>
              <el-col :span="2" style="margin-top: 48px">
                <!-- <el-button type="success" circle @click="filtrarBusqueda">
                  <i class="el-icon-search"></i>
                </el-button> -->
              </el-col>
            </el-row>

            <maca-datatable
              :url="urlTabla"
              :params="paramsTabla"
              :actualizar.sync="actualizarTabla"
              :bloquear.sync="bloquearTabla"
              v-if="mostrarTabla"
            >
              <el-table-column prop="cuit" label="CUIT" :min-width="10">
              </el-table-column>

              <el-table-column prop="nombre" label="Nombre" :min-width="10">
              </el-table-column>
              <el-table-column prop="apellido" label="Apellido" :min-width="10">
              </el-table-column>

              <el-table-column
                prop="razonSocial"
                label="Razon Social"
                :min-width="10"
              >
              </el-table-column>

              <el-table-column label="Direccion" :min-width="20">
                <template slot-scope="props">
                  <span
                    v-if="props.row.detalleParaje != null"
                    v-text="props.row.detalleParaje.nombre + ', '"
                  >
                  </span>
                  <span
                    v-if="props.row.detalleLocalidad != null"
                    v-text="props.row.detalleLocalidad.nombre + ', '"
                  >
                  </span>
                  <span
                    v-if="props.row.detalleDepartamento != null"
                    v-text="props.row.detalleDepartamento.nombre"
                  >
                  </span>
                  <span
                    v-if="props.row.detalleProvincia != null"
                    v-text="+' - ' + props.row.detalleProvincia.nombre"
                  >
                  </span>
                </template>
              </el-table-column>

              <el-table-column width="70">
                <template slot-scope="props">
                  <el-button
                    type="success"
                    circle
                    @click="obtenerBeneficiariosDelTransportista(props.row)"
                  >
                    <!-- @click="obtenerBeneficiariosDelTransportista(props.row)"
                 -->

                    <i class="el-icon-right"></i>
                  </el-button>
                </template>
              </el-table-column>
            </maca-datatable>
          </div>

          <div v-show="active == 1" class="contenedorStep">
            <strong style="text-align: right">Vehiculo seleccionado: </strong>
            <el-form-item label="Patente" props="patente" size="normal">
              <el-input v-model="patente" size="normal" disabled></el-input>
            </el-form-item>
            <el-form-item label="Capacidad" props="capacidadMax" size="normal">
              <el-input
                v-model="capacidadMax"
                size="normal"
                disabled
              ></el-input>
            </el-form-item>

            <maca-datatable
              :url="urlVehiculos"
              :params="paramsTablaVehiculos"
              :actualizar.sync="actualizarTablaVehiculos"
              :bloquear.sync="bloquearTabla"
            >
              <el-table-column prop="patente" label="patente" :min-width="10">
              </el-table-column>

              <el-table-column
                prop="capacidadMax"
                label="Capacidad"
                :min-width="10"
              >
              </el-table-column>

              <el-table-column width="70">
                <template slot-scope="props">
                  <el-button
                    type="success"
                    circle
                    @click="seleccionarVehiculo(props.row)"
                  >
                    <i class="el-icon-right"></i>
                  </el-button>
                </template>
              </el-table-column>
            </maca-datatable>
          </div>

          <div
            v-show="active == 2 && beneficiariosDeTransportista != null"
            class="contenedorStep"
            style="text-align: center"
          >
            <br /><br />
            <strong style="text-align: right"
              >Capacidad máxima de pasajeros:
              <span style="font-size: 16px"> {{ capacidadMax }}</span></strong
            >
            <br /><br />
            <strong
              v-show="valorRestriccion != 0 && valorRestriccion != capacidadMax"
              style="text-align: right; text-decoration: underline grey"
            >
              Capacidad máxima de pasajeros permitida:
              <span style="font-size: 16px">
                {{ valorRestriccion }}</span
              ></strong
            >
            <br /><br />
            <el-row :gutter="24">
              <el-col :span="17" style="margin-left: 40px">
                <el-checkbox-group
                  v-model="checkListDias"
                  @change="checkDiasSemanales"
                >
                  <el-checkbox-button label="Lunes">Lunes</el-checkbox-button>
                  <el-checkbox-button label="Martes">Martes</el-checkbox-button>
                  <el-checkbox-button label="Miercoles"
                    >Miercoles</el-checkbox-button
                  >
                  <el-checkbox-button label="Jueves">Jueves</el-checkbox-button>
                  <el-checkbox-button label="Viernes"
                    >Viernes</el-checkbox-button
                  >
                  <el-checkbox-button label="Sabado">Sabado</el-checkbox-button>
                  <el-checkbox-button label="Domingo"
                    >Domingo</el-checkbox-button
                  >
                </el-checkbox-group>
              </el-col>

              <el-col :span="1">
                <el-button type="success" circle @click="getBeneficiariosXDia">
                  <i class="el-icon-search"></i>
                </el-button>
              </el-col>
            </el-row>

            <br /><br />
            <el-form-item>
              <el-transfer
                v-model="form.beneficiariosSeleccionados"
                @change="controlarPasajeros"
                :props="{
                  key: 'value',
                  label: 'desc',
                }"
                :titles="['Beneficiarios', 'Pasajeros']"
                style="text-align: left; display: inline-block"
                :data="beneficiariosPorDia"
              >
                <span
                  class="transfer-footer"
                  slot="left-footer"
                  size="small"
                  style="font-size: 12px; font-style: italic"
                  >(*) Posee acompañante</span
                >
              </el-transfer>
            </el-form-item>
          </div>

          <div v-show="active == 3" style="text-align: center; height: 500px">
            <br /><br />

            <el-form-item>
              <GmapMap
                id="mapa"
                :zoom="15"
                :center="{ lat: -27.78215726918758, lng: -64.26060347741225 }"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in form.markers"
                  :position="m.position"
                  :clickable="true"
                  @click="toggleInfo(m)"
                >
                  <gmap-info-window
                    @closeclick="window_open = false"
                    :options="infoOptions"
                    :position="infoPosition"
                    :opened="infoOpened"
                  >
                    {{ m.position.full_name }}
                  </gmap-info-window>
                </GmapMarker>
                <DirectionsRenderer
                  travelMode="DRIVING"
                  :origin="origin"
                  :destination="destionation"
                />
              </GmapMap>

              <div id="right-panel">
                <div class="contenedorStep">
                  <label style="text-align: left; font-size: 13px">
                    <strong>Arrastar y soltar para cambiar orden: </strong>
                  </label>
                  <draggable
                    tag="ul"
                    v-model="arrayPasajeros"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                    @change="cambioDrag"
                  >
                    <transition-group
                      type="transition"
                      :name="!drag ? 'flip-list' : null"
                    >
                      <p
                        class="list-group-item estilosDragElement"
                        v-for="element in arrayPasajeros"
                        :key="element.order"
                      >
                        <el-popover
                          placement="bottom"
                          title="Dirección"
                          width="200"
                          trigger="click"
                          :content="element.datos.domicilio"
                        >
                          <i
                            slot="reference"
                            class="el-icon-info"
                            style="font-size: 16px; margin-right: 5px"
                          ></i>
                        </el-popover>

                        {{ element.name }}
                        <i
                          class="el-icon-d-caret"
                          style="
                            padding-top: 5px;
                            font-size: 15px;
                            float: right;
                          "
                        ></i>
                      </p>
                    </transition-group>
                  </draggable>
                  <el-button
                    type="success"
                    @click="calcularRuta()"
                    plain
                    icon="el-icon-map-location"
                    >Calcular Ruta</el-button
                  >
                </div>
                <p v-show="rutaCalculada">
                  <strong>Distancia Total ida: <span id="total"></span></strong>
                </p>
              </div>
            </el-form-item>
          </div>

          <div v-show="active == 4" style="text-align: left; height: 500px">
            <br /><br />
            <div class="contenedorStep" style="padding: 15px">
              <el-row :gutter="20">
                <el-col :span="8">
                  <p v-show="rutaCalculada">
                    <strong>
                      Distancia Total ida:
                      <span id="total">
                        {{ this.totalKm }} km ({{ this.totalKm * 2 }} km
                        total</span
                      ></strong
                    >
                  </p>
                </el-col>
                <el-col :span="8">
                  <strong>
                    <el-form-item
                      label="Distancia en Km a autorizar"
                      prop="totalKmAutorizar"
                    >
                      <el-input
                        type="number"
                        v-model.number="form.totalKmAutorizar"
                      >
                        <template slot="append">Km</template>
                      </el-input>
                    </el-form-item></strong
                  >
                </el-col>
              </el-row>
              <el-form-item>
                <p><strong>Viaje a partir de</strong></p>
                <el-date-picker
                  v-model="form.aPartirDe"
                  type="date"
                  placeholder="Seleccionar fecha"
                  format="dd-MM-yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>

          <el-form-item v-show="active != 0">
            <div style="display: flex">
              <div style="margin: auto; margin-right: 0px">
                <span
                  id="mensajeError"
                  style="margin-top: 15px; color: red; margin-right: 15px"
                ></span>
                <el-button style="margin-top: 15px" @click="atras"
                  >Atras</el-button
                >
                <el-button
                  v-show="active != 3"
                  style="margin-top: 15px"
                  @click="next"
                  >Siguiente</el-button
                >
                <el-button
                  v-show="active == 3"
                  style="margin-top: 15px"
                  @click="next"
                  >Crear Viaje</el-button
                >
              </div>
            </div>
          </el-form-item>
          <!--  <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item> -->
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
.vue-map-container {
  height: 450px;
  float: left;
  width: 58%;
}

#right-panel {
  font-family: "Roboto", "sans-serif";
  line-height: 30px;
  padding-left: 10px;
}

#right-panel select,
#right-panel input {
  font-size: 15px;
}

#right-panel select {
  width: 100%;
}

#right-panel i {
  font-size: 12px;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#map {
  height: 100%;
  float: left;
  width: 63%;
  height: 100%;
}

#right-panel {
  margin-left: 15px;
  height: 500px;

  overflow: auto;
}

.panel {
  margin-bottom: 50px;
  height: 100%;
  overflow: auto;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.estilosDragElement {
  margin: auto;
  margin-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 25px;
  padding-top: 5px;
  background-color: white;
  padding-bottom: 5px;
  text-align: left;
  border-radius: 10px;
}

.contenedorStep {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
</style>

<script>
import DirectionsRenderer from "../../DirectionsRenderer.js";
import draggable from "vuedraggable";

export default {
  display: "Transitions",
  order: 7,
  name: "nuevo-viaje",
  components: { DirectionsRenderer, draggable },
  data() {
    let _self = this;
    var checkMaxKm = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Por favor ingrese un valor"));
      }
      if (!Number.isInteger(value)) {
        callback(new Error("Ingrese un numero"));
      } else {
        if (value > this.maxPermitido) {
          callback(
            new Error(
              `El limite de kilómetros aprobados es ${this.maxPermitido}`
            )
          );
        } else {
          callback();
        }
      }
    };
    return {
      maxPermitido: null,

      patente: null,
      capacidadMax: null,

      beneficiariosDeTransportista: [],
      beneficiariosPorDia: [],
      dataBeneficiariosDeTransportista: null,
      start: "-27.78215726918758, -64.26062632988125",
      end: "-27.791933674014025, -64.26785756556858",
      center: { lat: 45.508, lng: -73.587 },
      currentPlace: null,

      active: 0,
      stepTransportistas: true,
      stepPasajeros: false,
      arrayPasajeros: [],
      idsBeneficiariosPasajeros: [],
      drag: false,
      rutaCalculada: false,
      capacidadMax: 0,
      totalKm: 0,
      form: {
        totalKmAutorizar: null,
        aPartirDe: null,
        markers: [],
        places: [],
        nombre: null,
        apellido: null,
        razonSocial: null,
        cuitCuil: null,
        domicilio: null,
        pais: null,
        centro: null,
        provincia: null,
        departamento: null,
        localidad: null,
        latitud: null,
        longitud: null,
        tipo: null,
        telefono: null,
        email: null,
        traslado: null,
        transportista: null,
        vehiculo: null,
        beneficiariosSeleccionados: [],
      },
      impedirClose: false,
      persona: null,
      valueVive: true,
      valueCentro: false,
      urlTiposBeneficiarios: "/tipoBeneficiario/obtenerTodosSelect",
      urlTransportista: "/transportista/buscar",
      urlVehiculos: "/transportistaVehiculo/obtenerTodos",

      getParams: this.getParamssSelect,
      urlCentro: "/beneficiario/buscar",
      esMail: false,
      esLocalidad: false,

      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      ubicacionActual: true,
      ubicacionIncompleta: false,
      longitudCentro: 0,
      latitudCentro: 0,
      infoPosition: null,
      infoContent: null,
      infoOpened: true,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },

      urlTabla: "/transportista/obtenerTodos",
      paramsTablaVehiculos: {},
      actualizarTablaVehiculos: true,
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      mostrarTabla: false,
      checkListDias: [],

      value: [],
      mensajeRestriccion: null,
      valorRestriccion: null,
      valorObtenidoRestriccion: null,

      formRules: {
        patente: [
          {
            required: true,
            message: "Por favor Seleccione un vehículo con patente.",
            trigger: "change",
          },
        ],
        capacidadMax: [
          {
            required: true,
            message: "Por favor Seleccione un vehículo con capacidad maxima.",
            trigger: "change",
          },
        ],
        totalKmAutorizar: [{ validator: checkMaxKm, trigger: "change" }],
        aPartirDe: [
          {
            type: "date",
            required: true,
            message: "Por favor introduzca la fecha.",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.geolocate();
    this.filtrarBusqueda();
    this.obtenerConfiguracionCapacidadMaxima();
    this.obtenerKmAutorizados();
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    origin() {
      if (!this.start) return null;
      return { query: this.start };
    },
    destionation() {
      if (!this.end) return null;
      return { query: this.end };
    },
  },
  created() {
    //  this.getBeneficiariosXDia();
  },
  methods: {
    checkDiasSemanales(value) {
      if (
        value[value.length - 1] == "Lunes" ||
        value[value.length - 1] == "Miercoles" ||
        value[value.length - 1] == "Viernes"
      ) {
        value = ["Lunes", "Miercoles", "Viernes"];
        this.checkListDias = ["Lunes", "Miercoles", "Viernes"];
      }
      if (
        value[value.length - 1] == "Martes" ||
        value[value.length - 1] == "Jueves" ||
        value[value.length - 1] == "Sabado"
      ) {
        value = ["Martes", "Jueves", "Sabado"];
        this.checkListDias = ["Martes", "Jueves", "Sabado"];
      }
    },
    async validarBeneficiarios() {
      console.log("transportista", this.form.transportista);
      let errorEncontrado = false;
      for (const element of this.idsBeneficiariosPasajeros) {
        let params;
        params = {
          beneficiarioID: element.beneficiarioID,
          transportistaID: this.form.transportista.id,
          fecha: this.form.aPartirDe,
        };
        let respuestaApi = await this.$maca.api.get(
          "/viaje/validarBeneficiarioOtroViaje",
          params,
          30000,
          true
        );
        console.log("respuesta", respuestaApi);

        if (respuestaApi != null) {
          if (respuestaApi.estado == -3) {
            this.$message({
              message: respuestaApi.excepcion,
              type: "error",
              showClose: true,
              duration: 0,
            });
            errorEncontrado = true;
          }
          if (respuestaApi.estado == -4) {
            this.$message({
              message:
                "El beneficiario se encuentra en otro viaje en la misma fecha",
              type: "error",
              showClose: true,
              duration: 0,
            });
            errorEncontrado = true;
          }
        } else {
          errorEncontrado = true;
        }
      }

      console.log("errorEncontrado", errorEncontrado);

      return errorEncontrado;
    },

    async obtenerKmAutorizados() {
      let respuestaApi = await this.$maca.api.get(
        "/configuracion/obtenerDatosPorClave",
        {
          clave: "VIAJE_KM_AUTORIZAR_MAX",
        }
      );

      if (respuestaApi != null) {
        this.maxPermitido = respuestaApi.datos.valor * 2;
      }
    },
    async getBeneficiariosXDia() {
      this.beneficiariosPorDia = [];
      this.dataBeneficiariosDeTransportista = [];
      let arrDia = [];
      for (let i = 0; i < this.checkListDias.length; i++) {
        arrDia.push({
          diaNombre: this.checkListDias[i],
        });
      }

      if (arrDia.length != 0) {
        let respuestaApi = await this.$maca.api.get(
          "/beneficiario/obtenerTodos",
          {
            arrDia: JSON.stringify(arrDia),
          }
        );

        if (respuestaApi != null) {
          this.dataBeneficiariosDeTransportista = respuestaApi.datos;
          respuestaApi.datos.forEach((item, index) => {
            let tieneAcompaniante = "";
            if (item.tieneAcompaniante != 0) {
              tieneAcompaniante = " (*)";
            }

            let direccion =
              item.detallePersona.detalleParaje != null
                ? item.detallePersona.detalleParaje.nombre + ", "
                : "";

            if (item.detallePersona.detalleParaje == null) {
              direccion =
                item.detallePersona.detalleLocalidad != null
                  ? item.detallePersona.detalleLocalidad.nombre
                  : "SIN UBICACION";
            }
            this.beneficiariosPorDia.push({
              value: index,
              desc:
                direccion +
                " - " +
                item.detallePersona.nombreUnificado +
                tieneAcompaniante,
            });
          });
          this.next();
        }
      } else {
        this.$message({
          message: "Debe seleccionar los dias para poder iniciar la búsqueda. ",
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
    },
    async obtenerConfiguracionCapacidadMaxima() {
      let respuestaApi = await this.$maca.api.get(
        "/configuracion/obtenerDatos",
        {
          id: 2,
          //nombre: 'VIAJE_TRANSP_CAPACIDAD_MAX', PREGUNTAR A FER DE ESTO
        }
      );

      if (respuestaApi != null) {
        this.mensajeRestriccion = respuestaApi.datos.descripcion;
        this.valorObtenidoRestriccion = respuestaApi.datos.valor;
      }
    },
    controlarPasajeros() {
      //IR AGREGANDO LOS CENTROS DE CADA PASAJERO PARA LOS MARCADORES DEL MAPA

      for (let i = 0; i < this.form.beneficiariosSeleccionados.length; i++) {
        let tieneAcompaniante =
          this.dataBeneficiariosDeTransportista[
            this.form.beneficiariosSeleccionados[i]
          ].tieneAcompaniante;

        let lengthTemporal = this.form.beneficiariosSeleccionados.length;
        if (tieneAcompaniante == 1) {
          lengthTemporal++;
        }
        if (lengthTemporal > this.capacidadMax) {
          this.form.beneficiariosSeleccionados.pop();
          this.$message({
            message:
              "El beneficiario que intenta agregar excede el limite de pasajeros.",
            type: "warning",
            showClose: true,
            duration: 0,
          });
        }
      }
    },

    cambioDrag() {},
    filterMethod(query, item) {
      return (
        item.initial.detalleBeneficiario.detallePersona.nombreUnificado.initial
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    },
    toggleInfo(marker, key) {
      this.infoContent = marker.position.full_name;
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
      }
    },

    async seleccionarVehiculo(datos) {
      this.form.vehiculo = datos;
      this.patente = datos.patente;
      this.capacidadMax = datos.capacidadMax;
    },
    async obtenerBeneficiariosDelTransportista(datos) {
      this.form.transportista = datos;
      this.paramsTablaVehiculos.transportistaID = datos.id;
      this.actualizarTablaVehiculos = true;
      this.next();
    },
    seleccionSelect() {
      this.paramsTabla = { transportistaID: this.form.transportista.id };

      this.mostrarTabla = true;
      this.actualizarTabla = true;
    },
    filtrarBusqueda() {
      if (this.esLocalidad) {
        this.paramsTabla = { localidadID: this.localidadID };
      } else {
        this.paramsTabla = { parajeID: this.parajeID };
      }
      this.mostrarTabla = true;
      this.actualizarTabla = true;
    },
    handleChange(node) {
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        if (node.length == 4) {
          this.esLocalidad = true;
        } else this.esLocalidad = false;

        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
        this.ubicacionActual = false;
      }
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const position = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers = [];
        this.markers.push({ position: position });
        this.places.push(this.currentPlace);
        this.center = position;
        this.currentPlace = null;
      }
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    atras() {
      this.active--;
    },
    async next() {
      if (this.active == 1) {
        if (this.form.vehiculo == null) {
          this.$message({
            message: "Por favor seleccione un vehiculo",
            type: "error",
            showClose: true,
            duration: 0,
          });
          return false;
        }
        this.capacidadMax = this.form.vehiculo.capacidadMax;
        this.valorRestriccion = this.valorObtenidoRestriccion / 100;
        this.valorRestriccion = parseInt(
          this.capacidadMax * this.valorRestriccion
        );
      }

      if (this.active == 2) {
        document.getElementById("mensajeError").innerHTML = "";
        if (this.form.beneficiariosSeleccionados.length > 0) {
          this.form.markers = [];
          this.arrayPasajeros = [];
          this.idsBeneficiariosPasajeros = [];

          for (
            let i = 0;
            i < this.form.beneficiariosSeleccionados.length;
            i++
          ) {
            let indice =
              this.dataBeneficiariosDeTransportista[
                this.form.beneficiariosSeleccionados[i]
              ];
            console.log(indice);
            let beneficiario = indice.detallePersona;
            this.arrayPasajeros.push({
              name: beneficiario.apellidoNombre,
              order: this.arrayPasajeros.length + 1,
              datos: beneficiario,
            });
            this.idsBeneficiariosPasajeros.push({
              beneficiarioID: indice.detalleTipoBeneficiario[0].beneficiarioID,
              detalleCentroBeneficiario: indice.detalleCentroBeneficiario,
            });

            const position = {
              full_name: beneficiario.apellidoNombre,
              lat: parseFloat(beneficiario.latitud),
              lng: parseFloat(beneficiario.longitud),
            };

            for (let j = 0; j < indice.detalleCentroBeneficiario.length; j++) {
              let centroBeneficiario =
                indice.detalleCentroBeneficiario[j].detalleCentro;

              if (
                this.chequearSiSeCargoMismoCentro(
                  centroBeneficiario.latitud,
                  centroBeneficiario.longitud
                )
              ) {
                for (let i = 0; i < this.arrayPasajeros.length; i++) {
                  if (
                    this.arrayPasajeros[i].datos.id == centroBeneficiario.id
                  ) {
                    this.arrayPasajeros[i] = {
                      name:
                        this.arrayPasajeros[i].name +
                        "(" +
                        beneficiario.apellidoNombre +
                        ")",
                      order: this.arrayPasajeros.length + 1,
                      datos: centroBeneficiario,
                    };
                  }
                }
              } else {
                this.arrayPasajeros.push({
                  name:
                    centroBeneficiario.razonSocial +
                    "(" +
                    beneficiario.apellidoNombre +
                    ")",
                  order: this.arrayPasajeros.length + 1,
                  datos: centroBeneficiario,
                });
              }
              const position = {
                full_name:
                  centroBeneficiario.razonSocial +
                  "(" +
                  beneficiario.apellidoNombre +
                  ")",
                lat: parseFloat(centroBeneficiario.latitud),
                lng: parseFloat(centroBeneficiario.longitud),
              };

              this.form.markers.push({ position: position });
            }

            this.form.markers.push({ position: position });
          }
          this.active++;
        } else {
        }
      } else if (this.active == 3) {
        if (this.totalKm != 0) {
          document.getElementById("mensajeError").innerHTML = "";
          this.active++;
        } else {
          document.getElementById("mensajeError").innerHTML =
            "Debe calcular la ruta para poder continuar";
        }
      } else if (this.active == 4) {
        if (this.form.transportista.centroID) {
          const errorEncontrado = await this.validarBeneficiarios();
          if (errorEncontrado) {
            return false;
          }
        }
        this.onSubmit();
      } else this.active++;
    },

    chequearSiSeCargoMismoCentro(latitud, longitud) {
      let b = false;
      for (let i = 0; i < this.form.markers.length; i++) {
        if (
          this.form.markers[i].position.lat == latitud &&
          this.form.markers[i].position.lng == longitud
        ) {
          i = this.form.markers.length;
          b = true;
        }
      }
      return b;
    },
    calcularRuta() {
      document.getElementById("mensajeError").innerHTML = "";
      this.form.markers = [];
      for (let i = 0; i < this.form.beneficiariosSeleccionados.length; i++) {
        let beneficiario =
          this.dataBeneficiariosDeTransportista[
            this.form.beneficiariosSeleccionados[i]
          ].detallePersona;

        const position = {
          full_name: beneficiario.apellidoNombre,
          lat: parseFloat(beneficiario.latitud),
          lng: parseFloat(beneficiario.longitud),
        };

        this.form.markers.push({ position: position });
      }

      var origen =
        this.arrayPasajeros[0].datos.latitud +
        ", " +
        this.arrayPasajeros[0].datos.longitud;
      var destino =
        this.arrayPasajeros[this.arrayPasajeros.length - 1].datos.latitud +
        ", " +
        this.arrayPasajeros[this.arrayPasajeros.length - 1].datos.longitud;
      var waypoints = [];

      for (let i = 1; i < this.arrayPasajeros.length - 1; i++) {
        waypoints.push({
          location:
            this.arrayPasajeros[i].datos.latitud +
            ", " +
            this.arrayPasajeros[i].datos.longitud,
        });
      }

      this.armadoDeRutas(origen, destino, waypoints);
    },
    armadoDeRutas(origen, destino, waypoints) {
      const map = new google.maps.Map(document.getElementById("mapa"));
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        draggable: false,
        map,
        // panel: document.getElementById("right-panel"), INDICACIONES DE RUTA
      });
      directionsRenderer.addListener("directions_changed", () => {
        this.computeTotalDistance(directionsRenderer.getDirections());
      });
      this.displayRoute(
        origen,
        destino,
        waypoints,
        directionsService,
        directionsRenderer
      );
    },
    displayRoute(origin, destination, waypoints, service, display) {
      service.route(
        {
          origin: origin,
          destination: destination,
          waypoints: waypoints,
          travelMode: google.maps.TravelMode.DRIVING,
          avoidTolls: true,
        },
        (result, status) => {
          if (status === "OK" && result) {
            display.setDirections(result);
            this.rutaCalculada = true;
          } else {
            alert("Could not display directions due to: " + status);
          }
        }
      );
    },
    computeTotalDistance(result) {
      let total = 0;
      const myroute = result.routes[0];

      if (!myroute) {
        return;
      }

      for (let i = 0; i < myroute.legs.length; i++) {
        total += myroute.legs[i].distance.value;
      }
      total = parseInt(total / 1000);

      let totalMaxPermitido =
        total * 2 > this.maxPermitido ? this.maxPermitido : total * 2;

      this.totalKm = total;
      this.form.totalKmAutorizar = totalMaxPermitido;

      document.getElementById("total").innerHTML = total + " km";
    },
    getParamssSelect(query) {
      let params = "";
      params = { nombre: query };
      return params;
    },
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.reiniciarValores();
    },
    reiniciarValores() {
      this.paramsTabla = {};
      this.form.beneficiariosSeleccionados = [];
      this.beneficiariosPorDia = [];

      this.patente = null;
      this.capacidadMax = null;
      this.checkListDias = [];

      this.persona = null;
      this.beneficiariosDeTransportista = [];
      this.dataBeneficiariosDeTransportista = null;
      this.start = "-27.78215726918758, -64.26062632988125";
      this.end = "-27.791933674014025, -64.26785756556858";
      this.center = { lat: 45.508, lng: -73.587 };
      this.currentPlace = null;

      this.active = 0;
      this.stepTransportistas = true;
      this.stepPasajeros = false;
      this.arrayPasajeros = [];
      this.idsBeneficiariosPasajeros = [];
      this.drag = false;
      this.rutaCalculada = false;
      this.capacidadMax = 0;
      this.totalKm = 0;

      this.form.totalKmAutorizar = null;
      this.form.aPartirDe = null;
      this.form.markers = [];
      this.form.places = [];
      this.form.nombre = null;
      this.form.apellido = null;
      this.form.razonSocial = null;
      this.form.cuitCuil = null;
      this.form.domicilio = null;
      this.form.pais = null;
      this.form.centro = null;
      this.form.provincia = null;
      this.form.departamento = null;
      this.form.localidad = null;
      this.form.latitud = null;
      this.form.longitud = null;
      this.form.tipo = null;
      this.form.telefono = null;
      this.form.email = null;
      this.form.traslado = null;
      this.form.transportista = null;

      this.impedirClose = false;
      this.persona = null;
      this.valueVive = true;
      this.valueCentro = false;
      this.urlTiposBeneficiarios = "/tipoBeneficiario/obtenerTodosSelect";
      this.urlTransportista = "/transportista/buscar";

      this.getParams = this.getParamssSelect;
      this.urlCentro = "/beneficiario/buscar";
      this.esMail = false;
      this.esLocalidad = false;

      this.ubicacionElegida = "";
      this.localidadID = null;
      this.departamentoID = null;
      this.provinciaID = null;
      this.paisID = null;
      this.parajeID = null;
      this.ubicacionActual = true;
      this.ubicacionIncompleta = false;
      this.longitudCentro = 0;
      this.latitudCentro = 0;
      this.infoPosition = null;
      this.infoContent = null;
      this.infoOpened = true;
      this.infoCurrentKey = null;
      (this.infoOptions = {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      }),
        (this.value = []);
      this.mensajeRestriccion = null;
      this.valorRestriccion = null;
      this.valorObtenidoRestriccion = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      let date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let fechaHoy;

      if (month < 10) {
        fechaHoy = `${year}-0${month}-${day}`;
      } else {
        fechaHoy = `${year}-${month}-${day}`;
      }

      let detalleBeneficiario = [];

      for (let i = 0; i < this.idsBeneficiariosPasajeros.length; i++) {
        for (
          let j = 0;
          j <
          this.idsBeneficiariosPasajeros[i].detalleCentroBeneficiario.length;
          j++
        ) {
          detalleBeneficiario.push({
            id: 0,
            beneficiarioID: this.idsBeneficiariosPasajeros[i].beneficiarioID,
            distanciaKm:
              this.idsBeneficiariosPasajeros[i].detalleCentroBeneficiario[j]
                .distanciaKm,
            accion: "A",
          });
        }
      }
      // Hacer Post
      let params = {
        transportistaID: this.form.transportista.id,
        fechaDesde: this.form.aPartirDe,
        fechaCreado: fechaHoy,
        capacidadMax: this.capacidadMax,
        kmTotalAutorizado: this.form.totalKmAutorizar,
        kmTotalSistema: this.totalKm,
        detalleBeneficiario: JSON.stringify(detalleBeneficiario),
        transportistaVehiculoID: this.form.vehiculo.id,
      };

      let respuestaApi = await this.$maca.api.post("/viaje/crear", params);

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Viaje creado con éxito!",
          type: "success",
        });

        this.$emit("listo");

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
        return false;
      }
      //
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>


<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>

<style >
.el-transfer {
  text-align: left;
  display: flex !important;
}
.el-transfer-panel {
  width: 100% !important;
}
.el-transfer__buttons {
  display: flex;
}

.el-transfer__buttons > * {
  margin: auto !important;
  margin-left: 10px !important;
}
</style>
   
