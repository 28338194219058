import { render, staticRenderFns } from "./nuevo.vue?vue&type=template&id=d5bb2350&scoped=true&"
import script from "./nuevo.vue?vue&type=script&lang=js&"
export * from "./nuevo.vue?vue&type=script&lang=js&"
import style0 from "./nuevo.vue?vue&type=style&index=0&id=d5bb2350&scoped=true&lang=css&"
import style1 from "./nuevo.vue?vue&type=style&index=1&id=d5bb2350&scoped=true&lang=css&"
import style2 from "./nuevo.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5bb2350",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d5bb2350')) {
      api.createRecord('d5bb2350', component.options)
    } else {
      api.reload('d5bb2350', component.options)
    }
    module.hot.accept("./nuevo.vue?vue&type=template&id=d5bb2350&scoped=true&", function () {
      api.rerender('d5bb2350', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/viajes/modales/nuevo.vue"
export default component.exports