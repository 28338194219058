<template>
  <div>
    <maca-modal
      titulo="Detalles del Viaje"
      :impedirClose="impedirClose"
      ref="modal"
    >
    <div class="contenedor"></div>
     <h1> </h1>
    </maca-modal>
  </div>
</template>
<style scoped>
.contenedor {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
</style>
<script>
export default {
  name: "modificar-usuario",
  data() {
    return {
      urlProvincia: "/provincia/obtenerTodosSelect",
      form: {
        nombre:null,
        razonSocial:null,
        telefonoTransportista:null,
        emailTransportista:null,        
        capacidadMax:null,
      },
      impedirClose: false,
      cargando: false,
      id: 0,
      idPadre: 0,
      persona: null,
      beneficiarios:[],
      distanciaReal:null,
      distanciaAutorizada:null,


    };
  },
  methods: {
    abrir(id, idPadre) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.idPadre = idPadre;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get(
        "/viaje/obtenerDatos",
        {
          id: this.id,
        }
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.razonSocial = respuestaApi.datos.detalleTransportista.razonSocial;
        this.form.nombre = respuestaApi.datos.detalleTransportista.apellido + ' '+ respuestaApi.datos.detalleTransportista.nombre;
        this.form.telefonoTransportista = respuestaApi.datos.detalleTransportista.telefono;
        this.form.emailTransportista = respuestaApi.datos.detalleTransportista.email;

      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        id: this.id,
        valor: this.form.valor,
        tipoDato: this.form.tipoDato,
        descripcion: this.form.descripcion,
      };

      let respuestaApi = await this.$maca.api.post(
        "/configuracion/actualizar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Configuración actualizada con éxito!",
          type: "success",
        });

        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>
