<template>
  <div>
    <maca-titulo-pagina
      icono="el-icon-guide"
      nombrePagina="Viajes"
    ></maca-titulo-pagina>

    <el-card :body-style="{ padding: '1.5%' }" style="margin: 2%">
      <div slot="header">
        <div style="margin-left: 0px">
          <maca-select-box
            class="buscador tamañoInputBucar"
            placeholder="Ingresar nombre de transportista"
            :item-label="['apellido', 'nombre', 'razonSocial']"
            v-model="transportista"
            :url="urlTransportista"
            :permitirInput="true"
            :clearable="true"
            @change="seleccionSelect"
            :necesitaParams="true"
            :getParams="getParams"
          ></maca-select-box>
        </div>
        <el-button
          class="botonmasIcono mostrarOcultarItem"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo Viaje</el-button
        >

        <el-button
          class="botonmasIcono ocultarMostrarItem"
          icon="el-icon-plus"
          type="primary"
          circle
          style="margin-left: 0.2rem"
          @click="$refs.modalNuevo.abrir()"
        ></el-button>
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div v-show="props.row.reasignado == 1" class="contenedor">
                <p>
                  <strong>Fecha reasignado: </strong
                  >{{ $maca.common.formatearFecha(props.row.reasignadoFecha) }}
                </p>

                <p>
                  <strong>Observaciones: </strong
                  >{{ props.row.reasignadoObservaciones }}
                </p>
              </div>
              <br />
              <div class="contenedor">
                <div v-if="props.row.detalleTransportista != null">
                  <p>
                    <strong>Teléfono transportista: </strong
                    >{{ props.row.detalleTransportista.telefono }}
                  </p>
                  <p>
                    <strong>Patente vehiculo: </strong
                    >{{
                      props.row.detalleTransportistaVehiculo != null &&
                      props.row.detalleTransportistaVehiculo.patente != null
                        ? props.row.detalleTransportistaVehiculo.patente
                        : "No especifica"
                    }}
                  </p>
                  <p>
                    <strong>Email transportista: </strong
                    >{{ props.row.detalleTransportista.email }}
                  </p>
                </div>

                <p>
                  <strong>Fecha de creación del viaje: </strong
                  >{{ $maca.common.formatearFecha(props.row.fechaCreado) }}
                </p>

                <br />
                <strong>Pasajeros: </strong>
                <div
                  v-for="(child, j) in props.row.detalleViajeBeneficiario"
                  :key="j"
                >
                  <br />
                  <div class="contenedorBeneficiario">
                    <strong>
                      <strong>**</strong>
                      <p>
                        <span
                          v-text="
                            child.detalleBeneficiario.detallePersona.nombre +
                            ' ' +
                            child.detalleBeneficiario.detallePersona.apellido
                          "
                        >
                        </span>
                      </p>
                      <p>
                        <span
                          v-if="
                            child.detalleBeneficiario.tieneAcompaniante == 1
                          "
                          v-text="'Posee acompañante'"
                        >
                        </span>
                      </p>
                      <p>
                        <span
                          v-text="
                            'Distancia al Centro: ' + child.distanciaKm + 'km'
                          "
                        >
                        </span>
                      </p>

                      <el-row :gutter="20">
                        <el-col :span="12">
                          <p>
                            <span
                              v-text="
                                'Teléfono: ' +
                                child.detalleBeneficiario.detallePersona
                                  .telefono
                              "
                            >
                            </span></p
                        ></el-col>
                        <el-col :span="12">
                          <p>
                            <span
                              v-text="
                                'Email: ' +
                                child.detalleBeneficiario.detallePersona.email
                              "
                            >
                            </span>
                          </p>
                        </el-col>
                      </el-row>
                    </strong>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="Razon Social/Apellido y Nombre del Transportista"
            min-width="60"
          >
            <template slot-scope="props">
              <div v-if="props.row.detalleTransportista != null">
                <span
                  v-if="props.row.detalleTransportista.razonSocial != null"
                  v-text="props.row.detalleTransportista.razonSocial"
                >
                </span>
                <span
                  v-else
                  v-text="
                    props.row.detalleTransportista.apellido +
                    ', ' +
                    props.row.detalleTransportista.nombre
                  "
                >
                </span>
              </div>
              <div v-else>
                <span style="color: brown; font-weight: bold"
                  >No se encontro el transportista</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Fecha Desde" min-width="40">
            <template slot-scope="props">
              <span v-text="$maca.common.formatearFecha(props.row.fechaDesde)">
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Distancia total" min-width="40">
            <template slot-scope="props">
              <span v-text="props.row.totalKm + 'km'"> </span>
            </template>
          </el-table-column>
          <el-table-column label="Distancia total autorizada" min-width="70">
            <template slot-scope="props">
              <span v-text="props.row.totalKmAutorizado + 'km'"> </span>
            </template>
          </el-table-column>
          <el-table-column label="Total Pasajeros" min-width="40">
            <template slot-scope="props">
              <span v-if="props.row.totalAcompaniantes != 0"
                >{{ calcularTotalPasajeros(props.row) }}
              </span>
              <span
                v-if="props.row.totalAcompaniantes == 0"
                v-text="props.row.totalPasajeros"
              >
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="40">
            <template slot-scope="props">
              <el-popover
                v-if="props.row.reasignado == 0"
                placement="top-start"
                title="Reasignar"
                width="200"
                trigger="hover"
                content="Reasignar viaje a otro transportista"
              >
                <el-button
                  slot="reference"
                  type="success"
                  circle
                  @click="$refs.modalReasignar.abrir(props.row)"
                  :disabled="!$store.getters.tienePermiso('M_USE')"
                >
                  <i class="el-icon-user"></i>
                </el-button>
              </el-popover>
              <span v-else><strong>REASIGNADO</strong></span>
            </template>
          </el-table-column>

          <el-table-column width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>

        <!-- <el-table
          v-loading="loading"
          :data="datosViajes"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div v-show="props.row.reasignado == 1" class="contenedor">
                <p>
                  <strong>Fecha reasignado: </strong
                  >{{ $maca.common.formatearFecha(props.row.reasignadoFecha) }}
                </p>

                <p>
                  <strong>Observaciones: </strong
                  >{{ props.row.reasignadoObservaciones }}
                </p>
              </div>
              <br />
              <div class="contenedor">
                <div v-if="props.row.detalleTransportista != null">
                  <p>
                    <strong>Teléfono transportista: </strong
                    >{{ props.row.detalleTransportista.telefono }}
                  </p>
                  <p>
                    <strong>Patente vehiculo: </strong
                    >{{ props.row.detalleTransportistaVehiculo.patente }}
                  </p>
                  <p>
                    <strong>Email transportista: </strong
                    >{{ props.row.detalleTransportista.email }}
                  </p>
                </div>

                <p>
                  <strong>Fecha de creación del viaje: </strong
                  >{{ $maca.common.formatearFecha(props.row.fechaCreado) }}
                </p>
                <p>
                  <strong>Cantidad pasajeros: </strong>
                  <span
                    v-if="props.row.totalAcompaniantes != 0"
                    v-text="
                      `${props.row.totalPasajeros} (${props.row.totalAcompaniantes} acompañantes inclusive)`
                    "
                  >
                  </span>
                  <span
                    v-if="props.row.totalAcompaniantes == 0"
                    v-text="props.row.totalPasajeros"
                  >
                  </span>
                </p>
                <br />
                <strong>Pasajeros: </strong>
                <div
                  v-for="(child, j) in props.row.detalleViajeBeneficiario"
                  :key="j"
                >
                  <br />
                  <div class="contenedorBeneficiario">
                    <strong>
                      <strong>**</strong>
                      <p>
                        <span
                          v-text="
                            child.detalleBeneficiario.detallePersona.nombre +
                            ' ' +
                            child.detalleBeneficiario.detallePersona.apellido
                          "
                        >
                        </span>
                      </p>
                      <p>
                        <span
                          v-if="
                            child.detalleBeneficiario.tieneAcompaniante == 1
                          "
                          v-text="'Posee acompañante'"
                        >
                        </span>
                      </p>
                      <p>
                        <span
                          v-text="
                            'Distancia al Centro: ' + child.distanciaKm + 'km'
                          "
                        >
                        </span>
                      </p>

                      <el-row :gutter="20">
                        <el-col :span="12">
                          <p>
                            <span
                              v-text="
                                'Teléfono: ' +
                                child.detalleBeneficiario.detallePersona
                                  .telefono
                              "
                            >
                            </span></p
                        ></el-col>
                        <el-col :span="12">
                          <p>
                            <span
                              v-text="
                                'Email: ' +
                                child.detalleBeneficiario.detallePersona.email
                              "
                            >
                            </span>
                          </p>
                        </el-col>
                      </el-row>
                    </strong>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="Razon Social/Apellido y Nombre del Transportista"
            min-width="60"
          >
            <template slot-scope="props">
              <div v-if="props.row.detalleTransportista != null">
                <span
                  v-if="props.row.detalleTransportista.razonSocial != null"
                  v-text="props.row.detalleTransportista.razonSocial"
                >
                </span>
                <span
                  v-else
                  v-text="
                    props.row.detalleTransportista.apellido +
                    ', ' +
                    props.row.detalleTransportista.nombre
                  "
                >
                </span>
              </div>
              <div v-else>
                <span style="color: brown; font-weight: bold"
                  >No se encontro el transportista</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Fecha Desde" min-width="40">
            <template slot-scope="props">
              <span v-text="$maca.common.formatearFecha(props.row.fechaDesde)">
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Distancia total" min-width="40">
            <template slot-scope="props">
              <span v-text="props.row.totalKm + 'km'"> </span>
            </template>
          </el-table-column>
          <el-table-column label="Distancia total autorizada" min-width="70">
            <template slot-scope="props">
              <span v-text="props.row.totalKmAutorizado + 'km'"> </span>
            </template>
          </el-table-column>
          <el-table-column label="Total Pasajeros" min-width="40">
            <template slot-scope="props">
              <span v-if="props.row.totalAcompaniantes != 0"
                >{{
                  props.row.totalPasajeros +
                  " (" +
                  props.row.totalAcompaniantes +
                  " acompañantes inclusive)"
                }}
              </span>
              <span
                v-if="props.row.totalAcompaniantes == 0"
                v-text="props.row.totalPasajeros"
              >
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="40">
            <template slot-scope="props">
              <el-popover
                v-if="props.row.reasignado == 0"
                placement="top-start"
                title="Reasignar"
                width="200"
                trigger="hover"
                content="Reasignar viaje a otro transportista"
              >
                <el-button
                  slot="reference"
                  type="success"
                  circle
                  @click="$refs.modalReasignar.abrir(props.row)"
                  :disabled="!$store.getters.tienePermiso('M_USE')"
                >
                  <i class="el-icon-user"></i>
                </el-button>
              </el-popover>
              <span v-else><strong>REASIGNADO</strong></span>
            </template>
          </el-table-column>

          <el-table-column width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table> -->
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
    <modal-reasignar
      ref="modalReasignar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-reasignar>
    <modal-detalle ref="modalDetalle"></modal-detalle>
  </div>
</template>
<style>
.contenedor {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
.contenedorBeneficiario {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.el-table .warning-row {
  background: rgba(216, 214, 214, 0.479) !important;
}
.ocultarMostrarItem {
  display: none;
}
.mostrarOcultarItem {
  display: block;
}
@media screen and (max-device-width: 560px) {
  .ocultarMostrarItem {
    display: block;
  }
  .mostrarOcultarItem {
    display: none;
  }
}

.tamañoInputBucar {
  width: 15rem;
}
@media screen and (max-device-width: 385px) {
  .tamañoInputBucar {
    width: 12rem;
  }
}
</style>
<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";
import ModalDetalle from "./modales/detalleViaje";
import ModalReasignar from "./modales/reasignarViaje";

export default {
  name: "usuario",
  components: {
    ModalNuevo,
    ModalModificar,
    ModalDetalle,
    ModalReasignar,
  },
  data() {
    return {
      loading: false,

      urlTransportista: "/transportista/buscar",
      transportista: null,
      getParams: this.getParamssSelect,

      urlTabla: "/viaje/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,

      totalPasajeros: [],
      totalAcompaniantes: [],
      datosViajes: [],
      detalleTransportista: false,
    };
  },
  created() {
    // this.getElementosTabla();
  },
  methods: {
    getParamssSelect(query) {
      let params = "";
      params = { nombre: query };
      return params;
    },
    seleccionSelect() {
      this.paramsTabla = { transportistaID: this.transportista.id };

      this.actualizarTabla = true;
    },
    calcularTotalPasajeros(datos) {
      let totalAcomp = 0;
      let totalPasajerosMasAcompaniantes = 0;

      datos.detalleViajeBeneficiario.forEach((element) => {
        if (
          element.detalleBeneficiario != null &&
          element.detalleBeneficiario.tieneAcompaniante == 1
        ) {
          totalAcomp++;
        }
      });
      totalPasajerosMasAcompaniantes =
        datos.detalleViajeBeneficiario.length + totalAcomp;

      return `${totalPasajerosMasAcompaniantes} (${totalAcomp} acompañantes inclusive)`;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.reasignado == 1) {
        return "warning-row";
      }
    },

    // async getElementosTabla() {
    //   this.loading = true;
    //   this.datosViajes = [];
    //   this.totalAcompaniantes = [];
    //   this.totalPasajeros = [];
    //   let respuestaApi = await this.$maca.api.get(
    //     "/viaje/obtenerTodos",
    //     this.paramsTabla
    //   );
    //   this.loading = false;

    //   if (respuestaApi != null) {
    //     let datos = respuestaApi.datos;

    //     for (let i = 0; i < datos.length; i++) {
    //       let totalAcomp = 0;
    //       let totalPasajerosMasAcompaniantes = 0;
    //       for (let j = 0; j < datos[i].detalleViajeBeneficiario.length; j++) {
    //         if (
    //           datos[i].detalleViajeBeneficiario[j].detalleBeneficiario !=
    //             null &&
    //           datos[i].detalleViajeBeneficiario[j].detalleBeneficiario
    //             .tieneAcompaniante == 1
    //         ) {
    //           totalAcomp++;
    //         }
    //       }

    //       totalPasajerosMasAcompaniantes =
    //         datos[i].detalleViajeBeneficiario.length + totalAcomp;

    //       this.datosViajes.push({
    //         id: datos[i].id,
    //         totalKm: datos[i].totalKm,
    //         totalKmAutorizado: datos[i].totalKmAutorizado,
    //         capacidadMax: datos[i].capacidadMax,
    //         totalPasajeros: totalPasajerosMasAcompaniantes,
    //         totalAcompaniantes: totalAcomp,
    //         reasignado: datos[i].reasignado,
    //         reasignadoObservaciones: datos[i].reasignadoObservaciones,
    //         reasignadoFecha: datos[i].reasignadoFecha,
    //         fechaCreado: datos[i].fechaCreado,
    //         fechaDesde: datos[i].fechaDesde,
    //         detalleTransportista: datos[i].detalleTransportista,
    //         detalleViajeBeneficiario: datos[i].detalleViajeBeneficiario,
    //         detalleTransportistaVehiculo: datos[i].detalleTransportistaVehiculo,
    //       });
    //     }

    //     this.detalleTransportista = true;
    //   }
    // },
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el viaje."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post("/viaje/eliminar", params);
      //

      // this.getElementosTabla();
      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Viaje borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleBloqueado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.bloqueado) {
        respuestaApi = await this.$maca.api.post("/user/bloquear", params);
      } else {
        respuestaApi = await this.$maca.api.post("/user/desbloquear", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {},
};
</script>
