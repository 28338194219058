var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("maca-titulo-pagina", {
        attrs: { icono: "el-icon-guide", nombrePagina: "Viajes" }
      }),
      _c(
        "el-card",
        {
          staticStyle: { margin: "2%" },
          attrs: { "body-style": { padding: "1.5%" } }
        },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "div",
                { staticStyle: { "margin-left": "0px" } },
                [
                  _c("maca-select-box", {
                    staticClass: "buscador tamañoInputBucar",
                    attrs: {
                      placeholder: "Ingresar nombre de transportista",
                      "item-label": ["apellido", "nombre", "razonSocial"],
                      url: _vm.urlTransportista,
                      permitirInput: true,
                      clearable: true,
                      necesitaParams: true,
                      getParams: _vm.getParams
                    },
                    on: { change: _vm.seleccionSelect },
                    model: {
                      value: _vm.transportista,
                      callback: function($$v) {
                        _vm.transportista = $$v
                      },
                      expression: "transportista"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "botonmasIcono mostrarOcultarItem",
                  staticStyle: { "margin-right": "0px" },
                  attrs: { icon: "el-icon-plus", type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.modalNuevo.abrir()
                    }
                  }
                },
                [_vm._v("Nuevo Viaje")]
              ),
              _c("el-button", {
                staticClass: "botonmasIcono ocultarMostrarItem",
                staticStyle: { "margin-left": "0.2rem" },
                attrs: { icon: "el-icon-plus", type: "primary", circle: "" },
                on: {
                  click: function($event) {
                    return _vm.$refs.modalNuevo.abrir()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "maca-datatable",
                {
                  attrs: {
                    url: _vm.urlTabla,
                    params: _vm.paramsTabla,
                    actualizar: _vm.actualizarTabla,
                    bloquear: _vm.bloquearTabla,
                    "row-class-name": _vm.tableRowClassName
                  },
                  on: {
                    "update:actualizar": function($event) {
                      _vm.actualizarTabla = $event
                    },
                    "update:bloquear": function($event) {
                      _vm.bloquearTabla = $event
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: props.row.reasignado == 1,
                                    expression: "props.row.reasignado == 1"
                                  }
                                ],
                                staticClass: "contenedor"
                              },
                              [
                                _c("p", [
                                  _c("strong", [_vm._v("Fecha reasignado: ")]),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$maca.common.formatearFecha(
                                        props.row.reasignadoFecha
                                      )
                                    ) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [_vm._v("Observaciones: ")]),
                                  _vm._v(
                                    _vm._s(props.row.reasignadoObservaciones) +
                                      " "
                                  )
                                ])
                              ]
                            ),
                            _c("br"),
                            _c(
                              "div",
                              { staticClass: "contenedor" },
                              [
                                props.row.detalleTransportista != null
                                  ? _c("div", [
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v("Teléfono transportista: ")
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            props.row.detalleTransportista
                                              .telefono
                                          ) + " "
                                        )
                                      ]),
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v("Patente vehiculo: ")
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            props.row
                                              .detalleTransportistaVehiculo !=
                                              null &&
                                              props.row
                                                .detalleTransportistaVehiculo
                                                .patente != null
                                              ? props.row
                                                  .detalleTransportistaVehiculo
                                                  .patente
                                              : "No especifica"
                                          ) + " "
                                        )
                                      ]),
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v("Email transportista: ")
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            props.row.detalleTransportista.email
                                          ) + " "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v("Fecha de creación del viaje: ")
                                  ]),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$maca.common.formatearFecha(
                                        props.row.fechaCreado
                                      )
                                    ) + " "
                                  )
                                ]),
                                _c("br"),
                                _c("strong", [_vm._v("Pasajeros: ")]),
                                _vm._l(
                                  props.row.detalleViajeBeneficiario,
                                  function(child, j) {
                                    return _c("div", { key: j }, [
                                      _c("br"),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "contenedorBeneficiario"
                                        },
                                        [
                                          _c(
                                            "strong",
                                            [
                                              _c("strong", [_vm._v("**")]),
                                              _c("p", [
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      child.detalleBeneficiario
                                                        .detallePersona.nombre +
                                                        " " +
                                                        child
                                                          .detalleBeneficiario
                                                          .detallePersona
                                                          .apellido
                                                    )
                                                  }
                                                })
                                              ]),
                                              _c("p", [
                                                child.detalleBeneficiario
                                                  .tieneAcompaniante == 1
                                                  ? _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          "Posee acompañante"
                                                        )
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]),
                                              _c("p", [
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "Distancia al Centro: " +
                                                        child.distanciaKm +
                                                        "km"
                                                    )
                                                  }
                                                })
                                              ]),
                                              _c(
                                                "el-row",
                                                { attrs: { gutter: 20 } },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c("p", [
                                                        _c("span", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              "Teléfono: " +
                                                                child
                                                                  .detalleBeneficiario
                                                                  .detallePersona
                                                                  .telefono
                                                            )
                                                          }
                                                        })
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c("p", [
                                                        _c("span", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              "Email: " +
                                                                child
                                                                  .detalleBeneficiario
                                                                  .detallePersona
                                                                  .email
                                                            )
                                                          }
                                                        })
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  }
                                )
                              ],
                              2
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Razon Social/Apellido y Nombre del Transportista",
                      "min-width": "60"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.detalleTransportista != null
                              ? _c("div", [
                                  props.row.detalleTransportista.razonSocial !=
                                  null
                                    ? _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            props.row.detalleTransportista
                                              .razonSocial
                                          )
                                        }
                                      })
                                    : _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            props.row.detalleTransportista
                                              .apellido +
                                              ", " +
                                              props.row.detalleTransportista
                                                .nombre
                                          )
                                        }
                                      })
                                ])
                              : _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "brown",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [_vm._v("No se encontro el transportista")]
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Fecha Desde", "min-width": "40" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$maca.common.formatearFecha(
                                    props.row.fechaDesde
                                  )
                                )
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Distancia total", "min-width": "40" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.totalKm + "km")
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Distancia total autorizada",
                      "min-width": "70"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  props.row.totalKmAutorizado + "km"
                                )
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Total Pasajeros", "min-width": "40" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.totalAcompaniantes != 0
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.calcularTotalPasajeros(props.row)
                                    ) + " "
                                  )
                                ])
                              : _vm._e(),
                            props.row.totalAcompaniantes == 0
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      props.row.totalPasajeros
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "40" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.reasignado == 0
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "Reasignar",
                                      width: "200",
                                      trigger: "hover",
                                      content:
                                        "Reasignar viaje a otro transportista"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "success",
                                          circle: "",
                                          disabled: !_vm.$store.getters.tienePermiso(
                                            "M_USE"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$refs.modalReasignar.abrir(
                                              props.row
                                            )
                                          }
                                        },
                                        slot: "reference"
                                      },
                                      [_c("i", { staticClass: "el-icon-user" })]
                                    )
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _c("strong", [_vm._v("REASIGNADO")])
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  circle: "",
                                  disabled: !_vm.$store.getters.tienePermiso(
                                    "B_USE"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.eliminar(props.row.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-reasignar", {
        ref: "modalReasignar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-detalle", { ref: "modalDetalle" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }