<template>
  <div>
    <maca-modal
      titulo="Modificar Centro"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Nombre" prop="nombre">
                <el-input maxlength="20" v-model="form.nombre"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Apellido" prop="apellido">
                <el-input v-model="form.apellido"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Razon Social" prop="razonSocial">
                <el-input maxlength="20" v-model="form.razonSocial"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="CUIT/CUIL" prop="cuitCuil">
                <el-input v-model="form.cuitCuil"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Domicilio" prop="domicilio">
            <el-input maxlength="30" v-model="form.domicilio"></el-input>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Pais" prop="pais" width="12">
                <maca-select-box
                  placeholder="Seleccionar"
                  v-model="form.pais"
                  :url="urlPais"
                  @change="resetearValor(1)"
                  ref="refPais"
                ></maca-select-box>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                v-if="form.pais"
                label="Provincia"
                prop="provincia"
                width="12"
              >
                <maca-select-box
                  placeholder="Seleccionar"
                  :disabled="!form.pais"
                  v-model="form.provincia"
                  @change="resetearValor(2)"
                  :necesitaParams="true"
                  :getParams="getParamsProvincia"
                  :url="urlProvincia"
                  ref="refProvincia"
                ></maca-select-box>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                v-if="form.provincia"
                label="Departamento"
                prop="departamento"
                width="12"
              >
                <maca-select-box
                  placeholder="Seleccionar"
                  @change="resetearValor(3)"
                  :disabled="!form.provincia"
                  v-model="form.departamento"
                  :necesitaParams="true"
                  :getParams="getParamsDepartamento"
                  :url="urlDepartamento"
                  ref="refDepartamento"
                ></maca-select-box>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                v-if="form.departamento"
                label="Localidad"
                prop="localidad"
                width="12"
              >
                <maca-select-box
                  placeholder="Seleccionar"
                  :disabled="!form.departamento"
                  v-model="form.localidad"
                  :url="urlLocalidad"
                  :necesitaParams="true"
                  :getParams="getParamsLocalidad"
                  ref="refLocalidad"
                ></maca-select-box>
              </el-form-item>
            </el-col>
          </el-row>
          <label
            v-if="form.pais"
            style="font-style: italic; color: grey; font-size: 14px"
          >
            Debe seleccionar hasta la localidad de la ubicación. De lo
            contrario, ésta no se cargará.</label
          >

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Teléfono" prop="telefono">
                <el-input maxlength="15" v-model="form.telefono"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Tipo de Transportista" prop="tipo">
            <maca-select-box
              v-model="form.tipo"
              :url="urlTiposBeneficiarios"
            ></maca-select-box>
          </el-form-item>

          <el-form-item label="Vive" prop="vive">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueVive"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="SI"
              inactive-text="NO"
            >
            </el-switch>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
     </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-usuario",
  data() {
    return {
      form: {
        nombre: null,
        apellido: null,
        razonSocial: null,
        cuitCuil: null,
        domicilio: null,
        pais: null,
        provincia: null,
        departamento: null,
        localidad: null,
        latitud: null,
        longitud: null,
        tipo: null,
        telefono: null,
        email: null,
        traslado: null,
      },
      impedirClose: false,
      persona: null,
      valueVive: true,

      urlTiposBeneficiarios: "/tipoBeneficiario/obtenerTodosSelect",

      formRules: {
        domicilio: [
          {
            required: false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: false,
            message: "Por favor introduzca el apellido.",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: false,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        pais: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        provincia: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        cuitCuil: [
          {
            required: true,
            message: "Por favor introduzca el CUIT o CUIL",
            trigger: "change",
          },
        ],
        departamento: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        localidad: [
          {
            required: false,
            message: "Por favor introduzca la localidad.",
            trigger: "change",
          },
        ],

        telefono: [
          {
            required: false,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],

        tipo: [
          {
            required: true,
            message: "Por favor introduzca el tipo.",
            trigger: "change",
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1: true,
      value2: true,
      valueTraslados: true,
      visible: false,
      edad: "",
      yaEligioFecha: false,
      eligioPais: false,

      idTipoActual: 0,
      tipoBeneficiarioIDActual: 0,
      mostrarMensaje: false,
      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get(
        "/transportista/obtenerDatos",
        {
          id: this.id,
        }
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.datos.detallePersona.nombre;
        this.form.apellido = respuestaApi.datos.detallePersona.apellido;
        this.form.razonSocial = respuestaApi.datos.detallePersona.razonSocial;
        this.form.cuitCuil = respuestaApi.datos.detallePersona.cuitCuil;
        this.form.domicilio = respuestaApi.datos.detallePersona.domicilio;
        this.form.telefono = respuestaApi.datos.detallePersona.telefono;

        this.form.fallecido = respuestaApi.datos.detallePersona.fallecido;
        if (this.form.fallecido == 0) {
          this.valueVive = true;
        } else {
          this.valueVive = false;
        }
        this.idTipoActual =
          respuestaApi.datos.detalleTransportistaTipoBeneficiario[0].id;
        this.tipoBeneficiarioIDActual =
          respuestaApi.datos.detalleTransportistaTipoBeneficiario[0].tipoBeneficiarioID;
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {

       let detalleTransportistaTipoBeneficiario = [
        {
          id: this.idTipoActual,
          tipoBeneficiarioID: this.tipoBeneficiarioIDActual,
          accion: "B",
        },
        { id: 0, tipoBeneficiarioID: this.form.tipo.id, accion: "A" },
      ];
      // Hacer Post
      let params = {
        id: this.id,
        razonSocial: this.form.razonSocial,
        cuitCuil: this.form.cuitCuil,
        domicilio: this.form.domicilio,
        email: this.form.email,
        nombre: this.form.nombre,
        apellido: this.form.apellido,
        localidadID: this.form.localidad != null ? this.form.localidad.id : "",
        telefono: this.form.telefono,
        fallecido: this.valueVive,
        detalleTransportistaTipoBeneficiario: JSON.stringify(
          detalleTransportistaTipoBeneficiario
        ),
      };

      let respuestaApi = await this.$maca.api.post(
        "/transportista/actualizar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Transportista actualizado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>
